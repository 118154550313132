<template>
  <div class="about css-helper">
    <mainBox></mainBox>
    <!-- <aside class="action">
      <div class="span">首页</div>
      <img src="../assets/img/InforCenter/juxing14.png" alt="" />
      <div class="span">关于我们</div>
    </aside> -->
    <div class="image about-title">关于我们</div>
    <div class="content" v-html="content">    
                

                
    </div>
    <footer-com></footer-com>
  </div>
</template>

<script>
import footerCom from "@/components/footer.vue";
import mainBox from "@/components/main.vue";
import { fetch } from '../api/https';

export default {
  data() {
    return {
      isAuto: true,
      id:'',
      content:''
    };
  },
  components: {
    footerCom,mainBox
  },
  mounted(){
    fetch("/getFootMenu").then((res) => {
        document.title = res.data[4].menu_seo_title;
        var id = res.data[4].id;
        console.log(id)
        fetch('/menu/'+id).then((result) => {
        console.log(result);
        this.content = result.data.menu_content
      })
      });
      
  }
};
</script>

<style lang="scss" scoped>
.action {
  display: block;
  max-width: 1400px;
  margin: 0 auto;
  font-size: 14px;
  box-sizing: border-box;
  margin-bottom: 51px;
  margin-top: 49px;
  height: 14px;
  font-family: Microsoft YaHei;
  font-weight: 300;
  color: #1a1a1a;
}
.action .span {
  font-family: MicrosoftYaHeiLight;
  float: left;
}
.action img {
  float: left;
  margin-top: 3px;
  height: 13px;
  width: 12px;
  margin-left: 6px;
  margin-right: 9px;
}
.about {
  .about-title {
    background-image: url("../assets/intro/title.png");
    color: #b87646;
    background-size: 100%;
    width: 1400px;
    margin: 0px auto;
    text-align: center;
    height: 24px;
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #B87646;
    line-height: 24px;
    margin-bottom: 31px;
  }

  .content {
    width: 1447px;
    // height: 483px;
    margin: 0 auto;
    background: url("../assets/intro/aboutus.png") top center no-repeat;
    background-size: 100% 100%;
    padding: 118px 123px 106px 123px;
    font-size: 14px;
    line-height: 22px;
    color: #333;
    margin-bottom: 36px;
    box-sizing: border-box;
  }
}
.css-helper{
  // height: 985px;
  // overflow: hidden;
}
.about_title{
    text-indent: 2em;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    line-height: 30px;
}
@media screen and (max-width: 1440px){
  .content{
        width: 1300px !important;
    margin: 0 auto;
    height: auto;
    background-size: 100% auto !important;
    padding-top: 116px;
    padding-left: 123px;
    box-sizing: border-box;
    background: url(/img/allBack.f980f998.png) no-repeat;
    background-size: 1300px 103% !important;
    padding-bottom: 109px;
    margin-bottom: 46px;
  }
    .about-title {
    background-image: url("../assets/intro/title.png");
    color: #b87646;
    background-size: 100%;
    width: 1266px !important;
    margin: 0px auto;
    text-align: center;
    height: 24px;
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #B87646;
    line-height: 24px;
    margin-bottom: 31px;
  }
  .about_title{
    width: 1055px;
  }
  .privacy_details{
    width: 1055px;

  }
}
</style>